import { Link } from 'gatsby';
import React from 'react';
import Logo from '../components/logo';
import SmallLogo from '../components/smallLogo';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSlider: false
        };
    }

    hideSlider = () => {
        this.setState({
            showSlider: false
        });
    };

    render() {
        const active = this.props.active;
        return (
            <div
                className={['header', this.props.index ? 'index' : '']
                    .join(' ')
                    .trim()}
            >
                <Link className={'link'} to="/">
                    <div>
                        <Logo />
                    </div>
                </Link>
                <div
                    className="menu"
                    onClick={() => {
                        this.setState({
                            showSlider: !this.state.showSlider
                        });
                    }}
                >
                    <i
                        className={
                            this.state.showSlider ? 'fa fa-times' : 'fa fa-bars'
                        }
                    ></i>
                </div>
                <div className="links">
                    <Link
                        className={[
                            'link',
                            active === 'Gallery' ? 'active' : ''
                        ]
                            .join(' ')
                            .trim()}
                        to="/"
                    >
                        gallery
                    </Link>
                    <Link
                        className={['link', active === 'About' ? 'active' : '']
                            .join(' ')
                            .trim()}
                        to="/about"
                    >
                        about
                    </Link>
                    <Link
                        className={[
                            'link',
                            active === 'Clients' ? 'active' : ''
                        ]
                            .join(' ')
                            .trim()}
                        to="/client-stories"
                    >
                        client stories
                    </Link>
                    <Link
                        className={[
                            'link',
                            active === 'Contact' ? 'active' : ''
                        ]
                            .join(' ')
                            .trim()}
                        to="/contact"
                    >
                        contact
                    </Link>
                    <div className="social-icons">
                        <a
                            className="link social"
                            href="https://www.instagram.com/headshotsbymallik/"
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <i className="fa fa-instagram"></i>
                        </a>
                    </div>
                </div>
                <div
                    className={[
                        'menu-slider',
                        this.state.showSlider ? 'show' : ''
                    ]
                        .join(' ')
                        .trim()}
                >
                    <div className="close-menu" onClick={this.hideSlider}>
                        <i className="fa fa-times"></i>
                    </div>
                    <div className="links" onClick={this.hideSlider}>
                        <Link
                            className={[
                                'link',
                                active === 'Corporate' ? 'active' : ''
                            ]
                                .join(' ')
                                .trim()}
                            to="/"
                        >
                            <SmallLogo />
                        </Link>
                        <Link
                            className={[
                                'link',
                                active === 'Corporate' ? 'active' : ''
                            ]
                                .join(' ')
                                .trim()}
                            to="/corporate"
                        >
                            corporate
                        </Link>
                        <Link
                            className={[
                                'link',
                                active === 'Personal-branding' ? 'active' : ''
                            ]
                                .join(' ')
                                .trim()}
                            to="/personal-branding"
                        >
                            personal branding
                        </Link>
                        <Link
                            className={[
                                'link',
                                active === 'Editorial' ? 'active' : ''
                            ]
                                .join(' ')
                                .trim()}
                            to="/editorial"
                        >
                            editorial
                        </Link>
                        <Link
                            className={[
                                'link',
                                active === 'Creative' ? 'active' : ''
                            ]
                                .join(' ')
                                .trim()}
                            to="/creative"
                        >
                            creative
                        </Link>
                        <Link
                            className={[
                                'link',
                                active === 'About' ? 'active' : ''
                            ]
                                .join(' ')
                                .trim()}
                            to="/about"
                        >
                            about
                        </Link>
                        <Link
                            className={[
                                'link',
                                active === 'Clients' ? 'active' : ''
                            ]
                                .join(' ')
                                .trim()}
                            to="/client-stories"
                        >
                            client stories
                        </Link>
                        <Link
                            className={[
                                'link',
                                active === 'Contact' ? 'active' : ''
                            ]
                                .join(' ')
                                .trim()}
                            to="/contact"
                        >
                            contact
                        </Link>
                        <div className="social-icons">
                            <a
                                className="link social"
                                href="https://www.instagram.com/headshotsbymallik/"
                                rel="noopener noreferrer"
                                target="_blank"
                            >
                                <i className="fa fa-instagram"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Header;
