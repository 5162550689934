import React from 'react';
import Header from './header';
import './layout.css';
import Logo from '../components/logo';
class Layout extends React.Component {
    componentDidMount() {
        console.log(
            '%c%s',
            'color: #0094ff; font-size: 18px;',
            'Made with ❤ by Arvind Kumar (https://arvind.io)\nFor more info, see /humans.txt'
        );
    }

    render() {
        return (
            <div className="layout-header">
                <div
                    className={['layout', this.props.index ? 'index' : '']
                        .join(' ')
                        .trim()}
                >
                    <Header
                        active={this.props.active}
                        title={this.props.title}
                        index={this.props.index}
                    />
                    <div className="container">{this.props.children}</div>
                </div>
            </div>
        );
    }
}

export default Layout;
